.Events{
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-wrap: wrap;
    /* border: 5px solid red; */
    justify-content: start;
    align-items: start;
    background-color: rgba(245, 222, 179, 0.85);;
}

.EventList{
    display: flex;
    flex-wrap: wrap;
    /* border: 2px solid black; */
    width: 100vw;
}

.Event_Modal{
    height: 100vh;
    width: 100vw;

    position: fixed;
    z-index: 90000012030234023040230420340230402304;
    background-color: rgba(99, 98, 98, 0.7);
}

.Events_data{
    width: 850px;
    height: 600px;
    background-color: white;
    border: 5px solid black;
    border-radius: 20px;
    position: absolute;
    left: 50%;
    top: 100px;
    transform: translateX(-50%);
    z-index: 349842304982034982094823094823094820934820948230948;

}

.Event_Section > h1{
    margin-top: 100px;
    margin-left: 100px;
    overflow: hidden;
}

.Event_One{
    height: 400px;
    width: 300px;
    display: flex;
    cursor: pointer;
    overflow: hidden;
    flex-direction: column;
    align-items: center;
    padding-top: 35px;
    margin: 40px;
    margin-left: 100px;
    overflow: hidden;
    /* margin-top: 100px; */
    border: 5px solid black;
    border-radius: 20px;
}

a{
    text-decoration: none;
}

.Event_One > h2{
    text-align: center;
    margin-top: 30px;
    color: black;
}

.register{
    background-color: #1572A1;
    width: 100px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: beige;
    border-radius: 10px;
    margin-left: 100px;
    margin-top: -30px;
    border: 2px solid black;
}

@media (max-width: 499px) {

    .Event_Section > h1{
        margin-left: 70px;
    }

    .Event_One{
        margin-left: 70px;
    }

    
}

@media (max-width: 386px) {

    .Event_Section > h1{
        margin-left: 40px;
    }

    .Event_One{
        margin-left: 40px;
    }

    
}

@media (max-width: 343px) {

    .Event_Section > h1{
        margin-left: 20px;
    }

    .Event_One{
        margin-left: 20px;
    }

    
}

@media (max-width: 330px) {

    .Event_One{
        width: 250px;
        height: 350px;
        font-size: 0.8rem;
    }
    
}


@media (max-width: 277px) {

    .Event_Section > h1{
        margin-left: 10px;
    }

    .Event_One{
        margin-left: 10px;
    }

    .register{
        margin-left: 30px;
    }

    
}


@media (max-width: 252px) {

    .Event_One{
        width: 230px;
        height: 330px;
        font-size: 0.6rem;

    }
    
}
