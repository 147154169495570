.Footer{
    min-height: 150px;
    width: 100vw;  
    font-size: 1rem;
    background-color: #E3BEC6;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.address{
    font-size: 0.8rem;
}


.SocialIcons{
    display: flex;
    gap: 10px;
    font-size: 2.5rem;
}



@media (max-width: 674px) {
    .Footer{
        min-height: 180px;
        flex-direction: column-reverse;
        padding-top: 30px;
    }

    .address{
        margin-top: 10px;
        margin-bottom: 10px;
    }
   

    .gmail{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}
