.Card{
    width: 300px;
    max-height: 400px;
    background-color: #EFDAD7;
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 2px solid black;
    border-radius: 20px;
}

.GenderLogo{
    width: 90%;
    height: 240px;
    margin-top: 20px;
    /* background-color: red; */
    display: flex;
    justify-content: center;
    align-items: center;
}

.Position{
    font-size: 1.6rem;
    font-weight: 800;
    
}

.Club{
    font-size: 1.2rem;
    font-weight: 800;
    margin-top: 10px;
}

.Name{
    margin-top: 10px;
    font-size: 1.7rem;
    font-weight: 700;
}

.diyaSinghal{
    border-radius: 10px;
}

.amishaDhingra{
    height: 100%;
    border-radius: 10px;
}

.aarav{
    border-radius: 10px;
    height: 100%;
}

@media (max-width: 640px) {

    .Card{
        width: 260px;
        min-height: 340px;
        padding-bottom: 20px;
    }

    
    
}

