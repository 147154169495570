.ProfileModalCard{
    display: flex;
    position: fixed;
    overflow: auto;
    /* scroll-behavior: smooth; */
    z-index: 50;
    height: 100vh;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 50px;
    /* border: 2px solid red; */
    background-color: rgba(239, 218, 215);
    padding: 30px;
}

.CloseButton{
    position: fixed;
    top: 1%;
    right: 1.5%;
    display: flex;
    /* border: 2px solid red; */
    align-items: center;
    justify-content: center;
    left: 90%;
    font-size: 3rem;
    /* border: 2px solid red; */
    z-index: 55;

}

.ProfileModalCard > h1{
    color: black;
    font-size: 2.3rem;
    height: fit-content;
    overflow: hidden;
    margin-bottom: 30px;
    /* border: 2px solid red; */
    min-height: 50px;
}

.paragraphs{
    /* border: 2px solid red; */
    overflow: auto;
    /* height: 800px; */
    /* margin-bottom: 40px; */
    padding-bottom: 10px;
}

.paragraphs > p{
    color: black;
    font-size: 1.4rem;
    /* overflow: hidden; */
    text-align: justify;
    /* width: 70%; */
    /* border: 2px solid red; */
    min-height: fit-content;
    /* padding: 5px; */
    overflow: auto;
    /* white-space: nowrap; */
}

@media (max-width: 898px) {

    .ProfileModalCard > h1{
text-align: center;    }

    .ProfileModalCard > p{
        color: black;
        font-size: 1.4rem;
        width: 80%;
        /* overflow: hidden; */
        text-align: justify;
    }
    
}


@media (max-width: 580px) {

    .ProfileModalCard > p{
        color: black;
        font-size: 1.4rem;
        text-align: justify;
        width: 96%;
    }
    
}

@media (max-width:388px) {

    .ProfileModalCard > p{
        font-size: 1rem;

    }
    
}

@media (max-width: 336px) {

    .ProfileModalCard > h1{
        font-size: 1.3rem;
    }
    
}
