.ClubCard{
    height: 450px;
    width: 330px;
    /* font-size: 3rem; */
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: whitesmoke;
    border: 3px solid #2155CD;
    border-radius: 20px;
}

.Image{
    margin-top: -30px;
}

.CardLogoImage{
    width: 180px;
}

.Title{
    font-size: 2.3rem;
    margin-top: 20px;
}


.ViewMoreBtn{
    cursor: pointer;
    margin-top: 50px;
    font-size: 1.3rem;
    color: #2155CD;
    /* border: 2px solid black; */
}

@media (max-width: 1149px) {
    .ClubCard{
        width: 310px;
        height: 420px;
    }

    .Title{
        font-size: 2rem;
    }
}

@media (max-width: 1095px) {
    .ClubCard{
        width: 290px;
        height: 400px;
    }

    .Title{
        font-size: 1.9rem;
    }
}

@media (max-width: 1015px) {
    .ClubCard{
        width: 270px;
        height: 380px;
    }

    .Title{
        font-size: 1.7rem;
    }
}

@media (max-width: 992px) {
    .ClubCard{
        width: 250px;
        height: 360px;
    }

    .Title{
        font-size: 1.5rem;
    }
}

@media (max-width: 992px) {
    .ClubCard{
        width: 250px;
        height: 360px;
    }

    .Title{
        font-size: 1.5rem;
    }
}

@media (max-width: 978px) {
    .ClubCard{
        width: 230px;
        height: 330px;
    }

    .Title{
        font-size: 1.2rem;
    }
    
}

@media (max-width: 898px) {
    .ClubCard{
        width: 300px;
        height: 400px;
    }
}

@media (max-width: 367px) {
    .ClubCard{
        width: 260px;
        height: 320px;
        padding-top: 30px;
    }

    .CardLogoImage{
        width: 140px;
    }

    
}

