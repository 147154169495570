.AboutUs_MainParent{
    height: 100vh;
    width: 100vw;
    background-color: #1572A1;
    display: flex;
    background-image: url("../Assets/aboutusBackground.jpg");
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
}

.ContentSection{
    width: 80%;
    height: 500px;
    overflow: auto;
    overflow-x: hidden;
    margin-top: 80px;
    /* background-color: wheat; */
    /* display: flex;
    
    flex-direction: column;
    align-items: start; */
}

.AnimationSection{
    width: 10%;
    height: 400px;
    /* background-color: wheat; */
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 50px;
    display: flex;
}


.ContentSection > h2{
    /* margin-top: 20px; */
    margin-left: 20px;
    font-size: 2.4rem;
    /* position: fixed; */
    top: 20;
    /* padding-top: 100px; */
    /* display: block; */
    /* border: 2px solid red; */
    width: 100%;
    /* background-color: #1572A1; */
    left: 20;
    color: #EFDAD7;
}

.ContentSection > p {
    color: #EFDAD7;
    margin-top: 20px;
    margin-left: 20px;
    width: 95%;
    font-size: 1.6rem;
    text-align: justify;
}

@media (max-width: 367px) {
    .ContentSection{
       
        margin-top: 20px;
    }
}