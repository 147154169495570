/* .scrollSnap{
    scroll-snap-type: y mandatory;
    scroll-snap-stop: always;

} */




.FounderSection{
    /* scroll-snap-align: start; */
    width: 100vw;
    display: flex;
    justify-content: center;
    gap: 50px;
    align-items: center;
    height: 100vh;
    /* background-color: rgba(154, 184, 192, 0.8); */
    background-color: rgba(245, 222, 179, 0.85);
}

.FounderSection_NaveenSir{
    display: flex;
    justify-content: center;
    flex-direction: row-reverse;
    align-items: center;
    /* gap: 10px; */
    background-color: rgba(245, 222, 179, 0.85);

    height: 100vh;
    width: 100vw;
}

.photo_NaveenSir{
    border-radius: 20px;
    border: 2px solid black;
    max-width: 400px;
    margin-right: 30px;
}

.photo_NaveenSir > img{
    max-width: 100%;
    height: auto;
}

.NaveenSir_Content{
    width: 770px;
    height: 510px;
    /* border: 2px solid red; */
    /* margin-left: ; */
}

.FounderContent_NaveenSir{
    width: 85%;
    font-size: 1.4rem;
    border: 2px solid rgb(35, 35, 35);
    margin-left: 20px;
    height: 300px;
    margin-top: 40px;
    border-radius: 20px;
    padding: 10px;
}

.FounderSection_tab{
    display: none;
}

.photo{
    border-radius: 20px;
    border: 2px solid black;
    max-width: 300px;
}

.photo > img{
    max-width: 100%;
    height: auto;
  
}

.content{
    width: 770px;
    height: 510px;
    display: flex;
    flex-direction: column;
    /* background-color: #3791aa; */
}

.Title{
    margin-top: 20px;
    height: 50px;
    overflow: hidden;
    margin-left: 20px;
    color: #454444;
    font-weight: 700;
    font-size: 2.5rem;
    margin-bottom: 20px;
}

.Logos{
    display: flex;
    align-items: center;
    margin-left: 20px;
    gap: 32px;
    /* width: 60%;
    flex-wrap: wrap;
    min-height: 300px; */
    margin-bottom: 20px;
}

.FounderContent{
    width: 85%;
    font-size: 1.4rem;
    border: 2px solid rgb(35, 35, 35);
    overflow: hidden;
    height: 45%;
    border-radius: 20px;
    padding: 10px;
}

.FounderSection_ViewMore{
    background-color: #454444;
    height: 40px;
    width: 120px;
    overflow: hidden;
    cursor: pointer;
    border: 2px solid black;
    color: whitesmoke;
    margin-top: 20px;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
}

.FounderContent_NaveenSir{
    overflow: hidden;
}

.FounderSection_ViewMore_NaveenSir{
    background-color: #454444;
    height: 40px;
    width: 120px;
    overflow: hidden;
    cursor: pointer;
    border: 2px solid black;
    color: whitesmoke;
    margin-top: 20px;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    margin-left: 20px;
}

.BoardmemberSection{
    /* scroll-snap-align: start; */
    width: 100vw;
    height: 1510px;
    overflow: hidden;
    
    background-color: rgba(245, 222, 179, 0.6);
}

.BoardTitle{
    font-size: 3rem;
    margin-top: 40px;
    margin-left: 90px;
    font-weight: 900;
}

.BoardMemebrContainer{
    width: 90%;
    margin-top: 20px;
    gap: 20px;
    height: 100%;
    margin-bottom: 200px;
    display: flex;
    flex-wrap: wrap;
    /* border: 2px solid red; */
    margin-left: 90px;
    /* margin-bottom: 190px; */
    overflow: hidden;
    padding-bottom: 60px;
}

@media (max-width: 1399px) {

    .BoardmemberSection{
        height: 2110px;
    }
    
}

@media (max-width: 1044px) {

    .BoardmemberSection{
        height: 3100px;
    }
    
}

@media (max-width: 898px) {
    .FounderSection{
        gap: 30px;
        height: 800px;
    }

    .photo{
        margin-left: 20px;
    }

    .Title{
        /* border: 2px solid red; */
        height: 90px;
        padding-bottom: 5px;
        /* min-height: 150px; */
        font-size: 1.8rem;
    }

    .Logos{
        flex-wrap: wrap;
        width: 80%;
        height: 270px;
        /* height: fit-content; */
        /* border: 2px solid red; */
    }

    .BoardmemberSection{
        height: 2900px;
        overflow: hidden;
    }

    .BoardMemebrContainer{
        height: 100%;
        margin-bottom: 140px;
        gap: 20px;
    }
}


@media (max-width: 820px) {

    .Logos{
        height: 290px;
        overflow: hidden;
    }
    
}


/* tablet */
    .FounderSection_mobile{
        display: none;
    }

    
    .FounderSection_NaveenSir_tab{
        display: none;
    }

    .FounderSection_NaveenSir_mobile{
        display: none;
    }

@media (max-width: 798px) {

    .FounderSection{
        display: none;
    }

    .FounderSection_NaveenSir{
        display: none;
    }

    .FounderSection_tab{
        display: block;
        background-color: rgba(245, 222, 179, 0.85);

    }

    .contentSectionOne_tab{
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 30px;
    }

    .photo_tab{
        margin-top: 80px;
        margin-left: 30px;
        object-fit: cover;
        border: 2px solid black;
        border-radius: 20px;
        max-width: 100%;
    }

    .photo_tab > img{
        max-width: 100%;
        height: auto;
    }

    .titleAndLogo{
        /* border: 2px solid red; */
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-top: 80px;
        flex-wrap: wrap;
        padding: 40px;
        gap: 50px;
        /* height: 400px; */
    }

    .Logos_tab{
        display: flex;
        align-items: centerf;
        flex-wrap: wrap;
        gap: 15px;
        
    }

    .content_tab{
        border: 2px solid black;
        width: 80%;
        margin-left: 25px;
        border-radius: 20px;
        height: 180px;
        overflow: hidden;
        padding: 20px;
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .FounderSection_ViewMore_AlokSirTab{
        background-color: #454444;
        height: 40px;
        width: 120px;
        overflow: hidden;
        cursor: pointer;
        border: 2px solid black;
        color: whitesmoke;
        margin-top: 20px;
        padding: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 10px;
        margin-left: 30px;
        margin-bottom: 30px;
    }

    .FounderSection_NaveenSir_tab{
        display: block;
        margin-top: 60px;
        width: 100vw;
        height: 100vh;
        background-color: rgba(245, 222, 179, 0.85);;
        /* display: flex;
        flex-direction: column;
        align-items: center; */
    }

    .NaveenSir_nameAndimg_tab{
        display: flex;
        justify-content: center;
        
    }

    .NaveenSir_nameAndimg_tab > h1{
        margin-top: 80px;
        margin-left: 20px;
    }

    .photo_NaveenSir_tab{
        margin-top: 20px;
        margin-left: 30px;
        object-fit: cover;
        border: 2px solid black;
        border-radius: 20px;
        max-width: 300px;
    }

    .photo_NaveenSir_tab > img{
        height: auto;
        max-width: 100%;
    }

    .NaveenSir_Content_tab{
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 5px;
    }

    .FounderContent_NaveenSir_tab{
        border: 2px solid black;
        margin: 40px;
        margin-bottom: 10px;
        border-radius: 20px;
        overflow: hidden;
        padding: 20px;
        height: 180px;
        width:80%;
        
    }

    .FounderSection_ViewMore_NaveenSirTab{
        background-color: #454444;
        height: 40px;
        width: 120px;
        overflow: hidden;
        cursor: pointer;
        border: 2px solid black;
        color: whitesmoke;
        margin-top: 5px;
        padding: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 10px;
        margin-left: 90px;
        margin-bottom: 30px;
        
    }

    .BoardmemberSection{
        display: flex;
        align-items: center;
        flex-direction: column;
        height: 2700px;
        /* border: 2px solid red; */
        /* padding-bottom: 0; */
        margin-left: 0;
    }

    .BoardTitle{
        margin-left: 0;
    }

    .BoardMemebrContainer{
        align-items: center;
        justify-content: center;
        margin-left: 0;
        margin-bottom: 0;
    }
}

@media (max-width: 766px) {

    .BoardmemberSection{
        
        height: 2900px;
        margin-bottom: 0;
    }

    .BoardMemebrContainer{
        margin-bottom: 0;
    }
    
    
}

@media (max-width: 688px) {

    .BoardmemberSection{
        height: 4900px;
    }
    
}

@media (max-width: 640px) {
    
    .BoardmemberSection{
        height: 2900px;
    }

}


@media (max-width: 628px) {

    .BoardmemberSection{
        height: 2900px;
    }
    
}

@media (max-width: 599px) {

    .BoardmemberSection{
        height: 4900px;
    }
    
}


@media (max-width: 580px) {
    .FounderSection_tab{
        display: none;
    }

    .FounderSection_mobile{
        display: block;
        background-color: rgba(245, 222, 179, 0.85);
        
    }

    .FounderSection_NaveenSir_tab{
        display: none;
    }

    .FounderContent_NaveenSir_tab{
        margin-top: 10px;
        border: 2px solid black;
        /* padding-top: 10px; */
    }

    .FounderSection_NaveenSir_mobile{
        padding-top: 20px;
        margin-top: 20px;
        display: block;
        height: 900px;
        overflow: hidden;
        width: 100vw;
        background-color: rgba(245, 222, 179, 0.85);

    }

    .Title_mobile{
        font-size: 1.6rem;
    }

    .contentSectionOne_mobile{
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .photo_mobile{
        margin-top: 80px;
        object-fit: cover;
        border: 2px solid black;
        border-radius: 20px;
        max-width: 70%;
    }

    .photo_mobile > img{
        max-width: 100%;
        height: auto;
    }

    .Title_mobile{
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .Logos_mobile{
        width: 70%;
        /* border: 2px solid red; */
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        gap: 25px;
    }

    .Logos_mobile > img{
        margin: 5px;
    }

    .content_mobile{
        /* border: 2px solid black; */
        display: flex;
        justify-content: center;
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .FounderContent_tab{
        width: 80%;
        border: 2px solid black;
        border-radius: 20px;
        overflow: hidden;
        height: 160px;
    }

    .BoardmemberSection{
        min-height: 800px;
    }
}

@media (max-width: 580px) {

    .NaveenSir_nameAndimg_mobile{
        flex-direction: column;
        align-items: center;

        /* border: 2px solid red; */
    
    }

    .photo_NaveenSir_mobile{
        max-width: 80%;
        margin-top: 20px;
        margin-left: 65px;
        /* border: 2px solid black; */
        border-radius: 20px;

    }

    .photo_NaveenSir_mobile >  img{
        height: auto;
        max-width: 100%;
        border: 2px solid black;
        border-radius: 20px;
    }

    .Title_mobile{
       
        text-align: center;
        /* border: 2px solid red; */
    }
    
}

@media (max-width: 455px) {

    .NaveenSir_Content_tab{
        height: 250px;
    }
    
}

@media (max-width: 393px) {

    .Title{
        font-size: 1.8rem;
    }

    .BoardTitle{
        font-size: 1.7rem;
    }

.NaveenSir_Content_tab{
    height: 250px;
    overflow: hidden;
}

.FounderContent_NaveenSir_tab{
    height: 100%;
}
    
}

@media (max-width: 393px) {
    .NaveenSir_Content_tab{
        height: 310px;
        overflow: hidden;
    }
    
    .FounderContent_NaveenSir_tab{
        height: 100%;
    } 
}

@media (max-width: 356px) {

    .Title_mobile{
        font-size: 1.4rem;
    }
    
}

@media (max-width: 368px) {

    .photo_NaveenSir_mobile{
        margin-left: 45px;
    }
    
}

@media (max-width: 356px) {
    .NaveenSir_Content_tab{
        height: 350px;
        overflow: hidden;
    }
    
    .FounderContent_NaveenSir_tab{
        height: 100%;
    } 
}

@media (max-width: 260px) {

    .photo_NaveenSir_mobile{
        margin-left: 25px;
    }
    
}

@media (max-width: 324px) {
    .NaveenSir_Content_tab{
        height: 400px;
        overflow: hidden;
    }
    
    .FounderContent_NaveenSir_tab{
        height: 100%;
    } 
}