@import url('https://fonts.googleapis.com/css2?family=Kodchasan:wght@200;400&display=swap');

*{
  box-sizing: border-box;
  overflow: auto;
  margin: 0;
  padding: 0;
}

a{
  text-decoration: none;
}

body{
  background-color: #E8F9FD;
  font-family: 'Kodchasan', sans-serif;
}

.background{
  width: 100vw;
  height: 100vw;
  background-color: black;
}

.Screen-Orientation-Locker{
  display: none;
}

@media (min-height: 0px) and (max-height: 400px) {
  /* CSS styles for landscape orientation */
  .Screen-Orientation-Locker{
    height: 100vh;
    width: 100vw;
    overflow: hidden;
    position: fixed;
    z-index: 23812039812093812093810938;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(162, 162, 160, 0.9);

  }

  .Screen-Orientation-Locker > h2{

    width: 30%;

  }
}